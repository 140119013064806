import React, { useState, useContext, useEffect } from "react";

import { FirebaseContext } from "../../Firebase";

import LinearProgress from "@mui/material/LinearProgress";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Add from "@mui/icons-material/Add";
import { ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const MailboxDialog = () => {
  const firebase = useContext(FirebaseContext);
  const [open, setOpen] = useState(false);
  const [emailProvider, setEmailProvider] = useState("google");
  const [inboxName, setInboxName] = useState("");
  const [userName, setUserName] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (userName.length > 0 && inboxName.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [userName, inboxName]);

  const toggleDialog = (toggle: boolean) => {
    setOpen(toggle);
    setInboxName("");
    setUserName("");
    setIsAuth(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setEmailProvider(event.target.value as string);
  };

  const handleUserChange = (event: any) => {
    setUserName(event.target.value as string);
  };

  const handleNameChange = (event: any) => {
    setInboxName(event.target.value as string);
  };

  const authenticate = async () => {
    setIsAuth(true);
    const authUrl = await firebase?.saveMailbox(
      inboxName,
      userName,
      emailProvider
    );
    if (authUrl?.data?.url) {
      window.location.href = authUrl?.data?.url;
    }
  };

  return (
    <>
      <ListItem key="footer" disablePadding>
        <ListItemButton onClick={() => toggleDialog(true)}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary="Add Mailbox"></ListItemText>
        </ListItemButton>
      </ListItem>
      <>
        <Dialog open={open} onClose={() => toggleDialog(false)}>
          {isAuth ? <LinearProgress /> : null}
          <DialogTitle>Add Mailbox</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please name your Mailbox. Then you'll need to authenticate to your
              specific mail provider so we can access the inbox.
            </DialogContentText>
            <Box component="form" noValidate autoComplete="off" p={4}>
              <TextField
                error={false}
                value={inboxName}
                autoFocus
                margin="dense"
                id="name"
                label="Mailbox Name"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(event) => handleNameChange(event)}
              />
              <TextField
                value={userName}
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                onChange={(event) => handleUserChange(event)}
              />
              <Box sx={{ marginTop: 2 }}>
                <FormControl sx={{ marginTop: "8" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Email Provider
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={emailProvider}
                    label="Email Provider"
                    onChange={handleChange}
                  >
                    <MenuItem value="google">Google</MenuItem>
                    <MenuItem value="microsoft">Microsoft</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button disabled={isAuth} onClick={() => toggleDialog(false)}>
              Cancel
            </Button>
            <Button disabled={isAuth || !isValid} onClick={authenticate}>
              Authenticate
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};
