import React from "react";
import { Card, CardContent, Skeleton } from "@mui/material";

const MailboxInfoCardSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Skeleton variant="text" width={200} height={20} />
        <Skeleton variant="text" width={100} height={16} />
        <Skeleton variant="text" width={150} height={16} />
        <Skeleton variant="text" width={120} height={16} />
      </CardContent>
    </Card>
  );
};

export default MailboxInfoCardSkeleton;
