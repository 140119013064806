import React, { useContext, useEffect, useState } from "react";
import { usePageTitle } from "../../utils/hooks";
import { Typography, Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FirebaseContext } from "../../components/Firebase";

import { IndeterminateLoading } from "../../components/IndeterminateLoading";
import { MailboxInfo } from "../../components/Mailbox";
import { MessagesTable } from "../../components/Mailbox";

export const InboxPage = () => {
  usePageTitle("Inbox");
  const firebase = useContext(FirebaseContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [mailboxId, setMailboxId] = useState<string>("");

  useEffect(() => {
    const unsubscribe = firebase?.listenForAuthChanges(
      (user) => {
        if (searchParams.get("mId") && user) {
          setUserId(user.uid);
          setMailboxId(searchParams.get("mId")!);
        }
      },
      (error) => {
        console.log(`ERROR: DO SOMETHING ABOUT THIS!!!!`);
      }
    );
    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, [searchParams]);

  return (
    <>
      {isLoading ? (
        <IndeterminateLoading />
      ) : (
        <>
          <MailboxInfo mailboxId={mailboxId} uid={userId} />
          <Box sx={{ width: "100%", height: 8 }} />
          <MessagesTable mailboxId={mailboxId} uid={userId} />
        </>
      )}
    </>
  );
};
