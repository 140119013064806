import { Routes, Route } from "react-router-dom";

import { InboxPage, LandingPage, Mailbox } from "../../pages";

export const RoutesComponent = () => {
  return (
    <Routes>
      <Route path={ROUTES.ROOT} element={<LandingPage />} />
      <Route path={ROUTES.INBOX} element={<InboxPage />} />
      <Route path={ROUTES.MAILBOX} element={<Mailbox />} />
    </Routes>
  );
};

export const ROUTES = {
  ROOT: "/",
  INBOX: "/inbox",
  MAILBOX: "/mailbox",
};
