import { useContext, useEffect } from "react";
import { FirebaseContext } from "../../components/Firebase";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { ROUTES } from "../../components/Routes";
import { IndeterminateLoading } from "../../components/IndeterminateLoading";

export const Mailbox = () => {
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    //TODO: Maybe auth user here to get UID rather than send in state?
    if (searchParams.get("code") && searchParams.get("state")) {
      firebase
        ?.exchangeGoogleToken(
          searchParams.get("code") as string,
          searchParams.get("state") as string
        )
        .then((res) => {
          const mailboxJson = JSON.parse(searchParams.get("state") as string);
          navigate(
            {
              pathname: ROUTES.MAILBOX,
              search: createSearchParams({
                mId: mailboxJson.mailboxId,
              }).toString(),
            },
            { replace: true }
          );
        })
        .catch((err: any) => {
          console.log(`Error: ${err.message}`);
          navigate(ROUTES.INBOX, { replace: true });
        });
    } else {
      //TODO: Think about error handling here!
      navigate(
        {
          pathname: ROUTES.ROOT,
        },
        { replace: true }
      );
    }
  }, [searchParams, firebase]);
  return <IndeterminateLoading />;
};
