import { useEffect } from "react";

export const usePageTitle = (title: string | null | undefined = "") => {
  const setPageTitle = (title: string | null | undefined) => {
    const newTitle = `${title ? `${title} - ` : ""} SummifyApp`;
    document.title = newTitle;
  };

  useEffect(() => {
    setPageTitle(title);
  }, [title]);

  return setPageTitle;
};
