import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";

import { FirebaseContext } from "../Firebase";

import { signOut } from "firebase/auth";

export interface ProfileMenuProps {
  isLoggedIn: boolean;
  profileUrl: string;
}

export const ProfileMenu = ({ isLoggedIn, profileUrl }: ProfileMenuProps) => {
  const firebase = useContext(FirebaseContext);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = async () => {
    // firebase?.getFirebaseAuth().signOut();
    await signOut(firebase!.getFirebaseAuth());
  };

  return (
    <>
      {isLoggedIn ? (
        <>
          <Tooltip title="Open Settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="avatar" src={profileUrl} />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key="signOut" onClick={handleSignOut}>
              <Typography textAlign="center">Sign out</Typography>
            </MenuItem>
          </Menu>
        </>
      ) : null}
    </>
  );
};
