import React, { useState, useContext, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { FirebaseContext } from "../../../components/Firebase";
import { usePageTitle } from "../../../utils/hooks";
import MailboxInfoCardSkeleton from "./skeleton";

export interface MailboxInfoProps {
  uid: string;
  mailboxId: string;
}

export const MailboxInfo = ({ uid, mailboxId }: MailboxInfoProps) => {
  const firebase = useContext(FirebaseContext);
  const [mailboxInfo, setMailboxInfo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [inboxName, setInboxName] = useState("Inbox");

  usePageTitle(inboxName);

  useEffect(() => {
    if (uid.length > 0 && mailboxId.length > 0) {
      firebase
        ?.getMailboxInfo(uid, mailboxId)
        .then((mbInfo) => {
          const { mailbox } = mbInfo;
          setMailboxInfo(mailbox);
          setIsLoading(false);
          setInboxName(mailbox.name);
        })
        .catch((err) => {
          console.log(`show some error screen: ${err}`);
        });
    }
  }, [uid, mailboxId]);

  return (
    <>
      {!isLoading ? (
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {mailboxInfo?.name}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Provider: {mailboxInfo?.provider}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Start Date: {mailboxInfo?.startDate}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              User: {mailboxInfo?.user}
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <MailboxInfoCardSkeleton />
      )}
    </>
  );
};
