import "./App.css";
import Firebase, { FirebaseContext } from "./components/Firebase";
import AppChrome from "./components/AppChrome";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <FirebaseContext.Provider value={new Firebase()}>
          <AppChrome />
        </FirebaseContext.Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
