import React, { useState, useContext, useEffect, useRef } from "react";

import { FirebaseContext } from "../Firebase";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { ListItemIcon, ListItemText, Theme, useTheme } from "@mui/material";

import { ProfileMenu } from "../ProfileMenu";
import { MailboxDialog } from "../Dialogs/MailboxDialog";

import { ROUTES, RoutesComponent } from "../Routes";
import { createSearchParams, useNavigate } from "react-router-dom";
import { MailboxType } from "../../types";
import { IndeterminateLoading } from "../../components/IndeterminateLoading";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

// export interface AppChromeProps {
//   theme: Theme;
// }
// { theme }: AppChromeProps

const AppChrome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);

  const [open, setOpen] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [profileUrl, setProfileUrl] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const [mailboxes, setMailboxes] = useState<MailboxType[]>([]);
  const [isMailboxLoading, setIsMailboxLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase?.listenForAuthChanges(
      (user) => {
        if (!user) {
          navigate("/", { replace: true });
          setIsUserSignedIn(false);
        } else {
          setIsUserSignedIn(true);
          setProfileUrl(user.photoURL ? user.photoURL : "");
          setDisplayName(user.displayName ? user.displayName : "");
        }
      },
      (error) => {
        console.error(`Error on Auth state change: ${error}`);
        navigate("/", { replace: true }); //TODO: Pass some kinda of error message
      }
    );
    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (isUserSignedIn) {
      firebase
        ?.getUserMailboxes()
        .then((mailboxes) => {
          setMailboxes(mailboxes);
          setIsMailboxLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isUserSignedIn]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment key="anchor">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ display: "flex" }}>
            {isUserSignedIn ? (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, ...(open && { display: "none" }) }}
                >
                  <MenuIcon />
                  <Typography
                    sx={{ paddingLeft: 4 }}
                    variant="h6"
                    noWrap
                    component="div"
                  >
                    Summify
                  </Typography>
                </IconButton>
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "end",
                  }}
                >
                  <ProfileMenu
                    isLoggedIn={isUserSignedIn}
                    profileUrl={profileUrl}
                  />
                </Box>
              </>
            ) : (
              <Typography
                sx={{ paddingLeft: 4 }}
                variant="h6"
                noWrap
                component="div"
              >
                Summify
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        {isUserSignedIn ? (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            {isMailboxLoading ? (
              <IndeterminateLoading />
            ) : (
              <List>
                {mailboxes.map((m) => {
                  return (
                    <ListItem key={m.id} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={m.name}
                          onClick={() => {
                            navigate(
                              {
                                pathname: ROUTES.INBOX,
                                search: createSearchParams({
                                  mId: m.id,
                                }).toString(),
                              },
                              { replace: true }
                            );
                          }}
                        ></ListItemText>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
            <List style={{ marginTop: "auto", marginBottom: "8" }}>
              <MailboxDialog />
            </List>
          </Drawer>
        ) : null}
        <Main open={open}>
          <DrawerHeader />
          <RoutesComponent />
        </Main>
      </Box>
    </React.Fragment>
  );
};

export default AppChrome;
