import { FirebaseApp, initializeApp, FirebaseAppSettings } from "firebase/app";
import {
  Auth,
  getAuth,
  NextOrObserver,
  ErrorFn,
  User,
  connectAuthEmulator,
} from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import {
  getDatabase,
  Database,
  connectDatabaseEmulator,
  set,
  ref,
  get,
  child,
  query,
  orderByChild,
  onValue,
} from "firebase/database";
import {
  getFunctions,
  Functions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import {
  connectStorageEmulator,
  getStorage,
  FirebaseStorage,
} from "firebase/storage";
import firebase from "firebase/compat/app";
import { MailboxType } from "../../types";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  private app: FirebaseApp;
  private auth: Auth;
  private database: Database;
  private functions: Functions;
  private storage: FirebaseStorage;
  constructor() {
    this.app = initializeApp(firebaseConfig);

    this.auth = getAuth(this.app);
    this.database = getDatabase(this.app);
    this.functions = getFunctions(this.app);
    this.storage = getStorage(this.app);

    //Local development!!!!!
    if (window.location.hostname === "localhost") {
      console.log(`****** Local Dev Mode *********`);
      connectAuthEmulator(this.auth, "http://localhost:9099", {
        disableWarnings: false,
      });
      connectFunctionsEmulator(this.functions, "localhost", 5101);
      connectDatabaseEmulator(this.database, "localhost", 5102);
      connectStorageEmulator(this.storage, "localhost", 9199);
    }
  }

  getFirebaseAuth = () => {
    return this.auth;
  };

  listenForAuthChanges = (
    observer: NextOrObserver<User | null>,
    error?: ErrorFn
  ) => {
    return this.auth.onAuthStateChanged(observer, error);
  };

  // https://stackoverflow.com/questions/27890737/firebase-google-auth-offline-access-type-in-order-to-get-a-token-refresh
  getUiConfig = () => {
    return {
      signInFlow: "popup",
      signInSuccessUrl: "/inbox",
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          scopes: ["https://mail.google.com/"],
          customParameters: {
            access_type: "offline",
          },
        },
      ],
    };
  };

  getFirebaseDatabase = (): Database => {
    return this.database;
  };

  getFirebaseFunctions = (): Functions => {
    return this.functions;
  };

  saveSecret = async (uid: string, secret: string) => {
    try {
      const save = httpsCallable(this.getFirebaseFunctions(), "saveSecret");
      const result = await save({ secret: secret, uid: uid });
      return result;
    } catch (err: any) {
      throw Error(err);
    }
  };

  saveUser = async (user: User) => {
    const db = this.getFirebaseDatabase();
    const dbRef = ref(db, `/users/${user.uid}`);
    await set(dbRef, {
      provider: user.providerId,
      displayName: user.displayName,
      photoUrl: user.photoURL,
    });
  };

  saveMailbox = async (
    name: string,
    user: string,
    provider: string
  ): Promise<any> => {
    const auth = this.getFirebaseAuth();
    const save = httpsCallable(this.getFirebaseFunctions(), "saveMailbox");
    const result = await save({
      uid: auth.currentUser?.uid,
      name: name,
      user: user,
      provider: provider,
      hostname: window.location.hostname,
    });
    return result;
  };

  exchangeGoogleToken = async (
    code: string,
    state: string
  ): Promise<string> => {
    const stateObj = JSON.parse(state);
    const exchange = httpsCallable(this.getFirebaseFunctions(), "exchangeCode");
    const status = await exchange({
      uid: stateObj.uid,
      hostname: window.location.hostname,
      code: code,
      mailboxRef: stateObj.mailboxId,
    });
    return "OK";
  };

  getUserMailboxes = async (): Promise<any> => {
    try {
      const auth = this.getFirebaseAuth();
      const uid = auth.currentUser?.uid;
      const mailboxes = httpsCallable(
        this.getFirebaseFunctions(),
        "getUserMailboxes"
      );
      const response: any = await mailboxes({
        uid: uid,
      });
      return response.data.mailboxes as MailboxType[];
    } catch (err: any) {
      throw Error("Sorry, there was a problem getting your mailboxes!");
    }
  };

  getMailboxInfo = async (uid: string, mid: string): Promise<any> => {
    const db = ref(this.getFirebaseDatabase());
    const mailbox = (await get(child(db, `/mailboxes/${uid}/${mid}`))).val();
    return {
      mailbox: mailbox,
    };
  };

  getMailboxMessages = async (mid: string): Promise<any> => {
    const messages: any[] = [];
    if (mid.length === 0) {
      return {
        messages: messages,
      };
    }
    return new Promise<any>((resolve, reject) => {
      const db = ref(this.getFirebaseDatabase());
      const msgs = query(
        ref(this.getFirebaseDatabase(), `/messages/${mid}`),
        orderByChild("msgDate")
      );
      const unsubscribe = onValue(
        msgs,
        (snapshot) => {
          snapshot.forEach((row) => {
            const r = row.val();
            messages.push({
              ...r,
              subject: r.subject || "(no subject)",
              from: r.from
                ?.reduce((acc: string[], curr: any) => {
                  acc.push(curr.name || curr.address);
                  return acc;
                }, [])
                .join(", "),
              to: r.to
                ?.reduce((acc: string[], curr: any) => {
                  acc.push(curr.name || curr.address);
                  return acc;
                }, [])
                .join(", "),
              msgDate: new Date(r.msgDate).toLocaleDateString(),
            });
          });
          unsubscribe();
          resolve({
            messages: messages.reverse(),
          });
        },
        (err) => {
          unsubscribe();
          reject("Sorry, we're having trouble loading your messages.");
        }
      );
    });
  };
}

export default Firebase;
