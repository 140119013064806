import React, { useState, useContext, useEffect } from "react";
import { FirebaseContext } from "../../../components/Firebase";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { IndeterminateLoading } from "../../../components/IndeterminateLoading";

export interface MessagesTableProps {
  uid: string;
  mailboxId: string;
}

export const MessagesTable = ({ uid, mailboxId }: MessagesTableProps) => {
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    if (uid.length > 0 && mailboxId.length > 0) {
      firebase
        ?.getMailboxMessages(mailboxId)
        .then((msg: any) => {
          const mapped = Object.keys(msg.messages).map((m) => {
            const row = msg.messages[m];
            return {
              ...row,
            };
          });
          setMessages(mapped);
          setIsLoading(false);
        })
        .catch((err: any) => {
          console.error(`Do something here: ${err.message}`);
        });
    }
  }, [uid, mailboxId]);

  return (
    <>
      {isLoading ? (
        <IndeterminateLoading />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subject</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell>{row.from}</TableCell>
                  <TableCell>{row.to}</TableCell>
                  <TableCell>{row.msgDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
