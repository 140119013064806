import React, { useState, useContext, useEffect } from "react";
import { usePageTitle } from "../../utils/hooks";
import { IndeterminateLoading, PageContainer } from "../../components";
import { FirebaseContext } from "../../components/Firebase";
import { useNavigate } from "react-router-dom";

import { Container, Typography, Button, Grid, Paper } from "@mui/material";
import { Google, Microsoft } from "@mui/icons-material";

// import * as firebaseui from "firebaseui";
// import "firebaseui/dist/firebaseui.css";
import {
  signInWithPopup,
  GoogleAuthProvider,
  UserCredential,
} from "firebase/auth";

export const LandingPage = () => {
  usePageTitle("Summify App");
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  const startGoogleLogin = async () => {
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "consent",
    });
    signInWithPopup(firebase!.getFirebaseAuth(), provider)
      .then(async (user: UserCredential) => {
        setIsUserSignedIn(true);
        setIsLoading(false);
        await firebase?.saveUser(user.user);
      })
      .catch((err: any) => {
        console.log(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const unsubscribe = firebase?.listenForAuthChanges(
      (user) => {
        if (!user) {
          setIsUserSignedIn(false);
        } else {
          setIsUserSignedIn(true);
          navigate("/inbox", { replace: true });
        }
      },
      (error) => {}
    );
    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Welcome to Summify
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" paragraph>
            Do you ever find yourself drowning in a sea of emails, struggling to
            keep up with the constant influx of messages? Say goodbye to email
            overload and hello to efficiency with Summify.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h5" gutterBottom>
              Key Features
            </Typography>
            <ul>
              <li>
                <strong>Automatic Email Summaries:</strong> Summify reads and
                analyzes your emails using cutting-edge artificial intelligence.
                It then generates concise and informative summaries, so you can
                quickly grasp the essential points without reading through
                lengthy messages.
              </li>
              <li>
                <strong>Scheduled Summaries:</strong> You can set a schedule for
                when you want Summify to deliver email summaries to your inbox.
                Whether it's daily, weekly, or on a custom schedule, we ensure
                you stay updated without the constant interruption of email
                notifications.
              </li>
              <li>
                <strong>Smart Prioritization:</strong> Our AI-powered
                prioritization system identifies emails that require immediate
                attention. No more sifting through your inbox to find urgent
                messages – we put them front and center, so you can focus on
                what matters most.
              </li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h5" gutterBottom>
              How It Works
            </Typography>
            <ol>
              <li>
                <strong>Connect Your Email:</strong> Link your email account
                securely with Summify. We support all major email providers to
                ensure a seamless experience.
              </li>
              <li>
                <strong>AI Magic:</strong> Our AI algorithms get to work,
                analyzing your emails for key information, important updates,
                and action items.
              </li>
              <li>
                <strong>Summaries and Prioritization:</strong> Receive neatly
                formatted email summaries and enjoy a clutter-free inbox with
                prioritized messages at your fingertips.
              </li>
            </ol>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">Sign in with:</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              startIcon={<Google />} // Use Google SVG icon
              fullWidth // Make the button span the full width
              onClick={async () => {
                startGoogleLogin();
              }}
            >
              Sign in with Google
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
